@import "../../../../vars";

#camera-pic-btn {
  width: 160px;
  display: flex;
  justify-content: center;
  img {
    width: 80px;
  }
}
