@import "../../vars";

#upload-popup {
  .upload-progress-wrapper {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10rem 0;

    .upload-progress {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 42px;

      .onprogress-icon {
        width: 124px;
      }
    }

    .onprogress-title {
      color: black;
      font-size: 18px;
      line-height: 0.95;
      font-weight: bold;
      text-align: center;
      margin: 0;
    }

    .onprogress-subtitle {
      color: black;
      font-weight: normal;
      font-size: 16px;
      text-align: center;
      margin-top: 6px;
      margin-bottom: 0;
    }
  }

  .upload-success-wrapper {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 0 16px 0;

    .onsuccess-icon {
      width: 65px;
      margin-bottom: 24px;
    }

    .onsuccess-description {
      margin-top: 3rem;
      font-size: 4.8rem;
      text-align: center;

      .title {
        color: black;
        font-size: 18px;
        line-height: 0.95;
        font-weight: bold;
        text-align: center;
        margin: 0;
      }

      .on-success-upload-second-line {
        color: black;
        font-weight: normal;
        font-size: 16px;
        text-align: center;
        margin-top: 6px;
        margin-bottom: 0;
      }
    }

    .on-success-btn {
      color: black;
      margin-top: 24px;
      padding: 14px 25px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      background-color: #d7c9ff;
      font-weight: bold;
      font-size: 17px;
      height: 48px;
      border-radius: 35px;
      border: 2px solid black;
    }

    .onsuccess-image-counter {
      justify-content: center;
      align-items: center;

      &.show {
        display: flex;
      }

      .image-counter-icon {
        width: 8rem;
        margin-left: 2rem;
      }

      .image-counter-text {
        color: black;
        font-weight: normal;
        font-size: 16px;
        text-align: center;
        margin-top: 6px;
      }
    }
  }
}
