@import '../../vars';

#horizontal-screen {
    width: 100vw;
    height: 100vh;
    position: absolute;
    background: $secondary-color;
    color: white;
    top: 0;
    left: 0;
    z-index: 999;
    display: none;
    justify-content: center;
    align-items: center;
    font-size: 3vw;

    @media screen and (orientation:landscape) {
        display: flex;
    }
}