@import "../../vars";

#page404 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .page404-icon {
    width: 90px;
    margin-bottom: 20px;
  }

  .page404-title {
    color: black;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    line-height: 0.95;
    margin-bottom: 0;
  }

  .page404-subtitle {
    color: black;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    margin-bottom: 0;
  }
}
