@import url(//fonts.googleapis.com/earlyaccess/opensanshebrew.css);
.main-screen {
  width: 100%;
  height: 100%;
  background-image: url(/static/media/gradientBackground.1b0e5b61.png);
  background-size: cover;
  position: relative;
  padding: 4%; }

.main-screen-body {
  flex: 1 1;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%; }

.main-screen-footer {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  margin-bottom: 32px; }

.main-screen-wrapper {
  width: 100%;
  height: 100%;
  background-image: -webkit-linear-gradient(left, black 5%, transparent 5%, transparent 45%, black 45%, black 75%, transparent 75%, transparent 95%, black 95%), -webkit-linear-gradient(top, black 2%, transparent 2%, transparent 10%, black 10%, black 25%, transparent 25%, transparent 45%, black 45%, black 50%, transparent 50%, transparent 60%, black 60%, black 75%, transparent 75%, transparent 98%, black 98%), -webkit-linear-gradient(top, black 2%, transparent 2%, transparent 20%, black 20%, black 35%, transparent 35%, transparent 45%, black 45%, black 50%, transparent 50%, transparent 70%, black 70%, black 85%, transparent 85%, transparent 98%, black 98%), -webkit-linear-gradient(right, black 5%, transparent 5%, transparent 45%, black 45%, black 75%, transparent 75%, transparent 95%, black 95%);
  background-image: linear-gradient(to right, black 5%, transparent 5%, transparent 45%, black 45%, black 75%, transparent 75%, transparent 95%, black 95%), linear-gradient(to bottom, black 2%, transparent 2%, transparent 10%, black 10%, black 25%, transparent 25%, transparent 45%, black 45%, black 50%, transparent 50%, transparent 60%, black 60%, black 75%, transparent 75%, transparent 98%, black 98%), linear-gradient(to bottom, black 2%, transparent 2%, transparent 20%, black 20%, black 35%, transparent 35%, transparent 45%, black 45%, black 50%, transparent 50%, transparent 70%, black 70%, black 85%, transparent 85%, transparent 98%, black 98%), linear-gradient(to left, black 5%, transparent 5%, transparent 45%, black 45%, black 75%, transparent 75%, transparent 95%, black 95%);
  background-size: 100% 2px, 2px 100%, 2px 100%;
  background-position: top left, top left, top right, bottom right;
  background-repeat: no-repeat;
  padding: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; }

.popup-screen {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  background: rgba(255, 255, 255, 0.5); }
  .popup-screen .popup-area {
    width: 80%;
    border: 3px solid black;
    background: white;
    padding: 3.2rem;
    border-radius: 26px; }
  .popup-screen .popup-close {
    margin-top: 2px;
    margin-right: 2px; }
    .popup-screen .popup-close img {
      width: 18px; }
    .popup-screen .popup-close.show {
      display: block; }
    .popup-screen .popup-close.hide {
      display: none; }

.memo-ad-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }
  .memo-ad-wrapper .want-memo-at-your-event {
    color: black;
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    line-height: 0.95;
    margin-bottom: 12px;
    margin-top: 18px; }
  .memo-ad-wrapper .click-for-more-details {
    color: black;
    padding: 14px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    background-color: white;
    font-weight: bold;
    margin-bottom: 0;
    font-size: 17px;
    height: 48px;
    border-radius: 35px;
    border: 2px solid black; }

.open-camera-wrapper {
  text-align: center; }

.event-title {
  font-weight: normal;
  font-size: 20px;
  margin-bottom: 10px; }

.event-subtitle {
  font-weight: bold;
  font-size: 40px;
  margin-top: 0;
  margin-bottom: 100px; }

.camera-img {
  -webkit-transition: all 250ms linear;
  transition: all 250ms linear;
  margin-bottom: 150px; }

.camera-img:focus {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  opacity: 0; }

#page404 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  #page404 .page404-icon {
    width: 90px;
    margin-bottom: 20px; }
  #page404 .page404-title {
    color: black;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    line-height: 0.95;
    margin-bottom: 0; }
  #page404 .page404-subtitle {
    color: black;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    margin-bottom: 0; }

#horizontal-screen {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background: #ed4466;
  color: white;
  top: 0;
  left: 0;
  z-index: 999;
  display: none;
  justify-content: center;
  align-items: center;
  font-size: 3vw; }
  @media screen and (orientation: landscape) {
    #horizontal-screen {
      display: flex; } }

#upload-popup .upload-progress-wrapper {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10rem 0; }
  #upload-popup .upload-progress-wrapper .upload-progress {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 42px; }
    #upload-popup .upload-progress-wrapper .upload-progress .onprogress-icon {
      width: 124px; }
  #upload-popup .upload-progress-wrapper .onprogress-title {
    color: black;
    font-size: 18px;
    line-height: 0.95;
    font-weight: bold;
    text-align: center;
    margin: 0; }
  #upload-popup .upload-progress-wrapper .onprogress-subtitle {
    color: black;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    margin-top: 6px;
    margin-bottom: 0; }

#upload-popup .upload-success-wrapper {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 0 16px 0; }
  #upload-popup .upload-success-wrapper .onsuccess-icon {
    width: 65px;
    margin-bottom: 24px; }
  #upload-popup .upload-success-wrapper .onsuccess-description {
    margin-top: 3rem;
    font-size: 4.8rem;
    text-align: center; }
    #upload-popup .upload-success-wrapper .onsuccess-description .title {
      color: black;
      font-size: 18px;
      line-height: 0.95;
      font-weight: bold;
      text-align: center;
      margin: 0; }
    #upload-popup .upload-success-wrapper .onsuccess-description .on-success-upload-second-line {
      color: black;
      font-weight: normal;
      font-size: 16px;
      text-align: center;
      margin-top: 6px;
      margin-bottom: 0; }
  #upload-popup .upload-success-wrapper .on-success-btn {
    color: black;
    margin-top: 24px;
    padding: 14px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    background-color: #d7c9ff;
    font-weight: bold;
    font-size: 17px;
    height: 48px;
    border-radius: 35px;
    border: 2px solid black; }
  #upload-popup .upload-success-wrapper .onsuccess-image-counter {
    justify-content: center;
    align-items: center; }
    #upload-popup .upload-success-wrapper .onsuccess-image-counter.show {
      display: flex; }
    #upload-popup .upload-success-wrapper .onsuccess-image-counter .image-counter-icon {
      width: 8rem;
      margin-left: 2rem; }
    #upload-popup .upload-success-wrapper .onsuccess-image-counter .image-counter-text {
      color: black;
      font-weight: normal;
      font-size: 16px;
      text-align: center;
      margin-top: 6px; }

#error-popup .error-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10rem 0; }
  #error-popup .error-wrapper .error-icon {
    width: 27rem;
    margin-bottom: 30px; }
  #error-popup .error-wrapper .error-title,
  #error-popup .error-wrapper .error-subtitle {
    color: black;
    font-size: 18px;
    line-height: 0.95;
    font-weight: bold;
    text-align: center;
    margin: 4px; }

#blocked-popup .blocked-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3rem 0 10rem; }
  #blocked-popup .blocked-wrapper .blocked-icon {
    width: 30.93rem; }
  #blocked-popup .blocked-wrapper .blocked-title {
    font-weight: bold;
    font-size: 13.6rem;
    text-align: center;
    color: #ffd321;
    padding: 0 5rem;
    line-height: .95;
    margin: 3rem 0; }
  #blocked-popup .blocked-wrapper .blocked-subtitle {
    color: #ed4466;
    font-weight: normal;
    font-size: 4rem;
    text-align: center; }

#camera-pic-btn {
  width: 160px;
  display: flex;
  justify-content: center; }
  #camera-pic-btn img {
    width: 80px; }

#camera-flip-btn img {
  width: 27px; }

#camera-upload-btn img {
  width: 27px; }

#camera-preview-btn {
  color: black;
  margin-left: 20px;
  margin-right: 20px;
  padding: 14px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  background-color: #d7c9ff;
  font-weight: bold;
  margin-bottom: 0;
  font-size: 17px;
  height: 48px;
  border-radius: 35px;
  border: 2px solid black; }

#camera-trash-btn img {
  width: 25px; }

#camera-Sticker-btn img {
  width: 27px; }

.sticker-item {
  width: 27%;
  height: 50%;
  margin-bottom: 3rem;
  display: flex;
  justify-content: center;
  align-items: center; }
  .sticker-item.loading .loading-image {
    display: block; }
  .sticker-item.loading .sticker-image {
    display: none; }
  .sticker-item .loading-image {
    width: 30%;
    display: none; }
  .sticker-item .sticker-image {
    width: 100%;
    max-height: 100%; }

#camera-sticker-list {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 4;
  height: 40%;
  background: #f1f1f1;
  width: 100%;
  border-radius: 26px 26px 0 0;
  border: 3px solid black;
  border-bottom: none;
  direction: ltr; }
  #camera-sticker-list .close-btn {
    background: none;
    border: none;
    padding: 0;
    position: absolute;
    right: 10px;
    top: 16px;
    margin: 0;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center; }
    #camera-sticker-list .close-btn img {
      width: 20px; }
  #camera-sticker-list .stickers-wrapper {
    overflow-y: auto;
    height: 85%;
    position: absolute;
    margin-right: 10px;
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 5% 10% 10%;
    /* Custom scrollbar styles */ }
    #camera-sticker-list .stickers-wrapper::-webkit-scrollbar {
      width: 12px;
      /* Width of the scrollbar */ }
    #camera-sticker-list .stickers-wrapper::-webkit-scrollbar-track {
      background: black;
      /* Background of the scrollbar track */
      border-radius: 10px; }
    #camera-sticker-list .stickers-wrapper::-webkit-scrollbar-thumb {
      background-color: #d5ff6f;
      /* Color of the scrollbar thumb */
      border-radius: 10px;
      border: 3px solid black;
      /* Padding around the thumb */ }
    #camera-sticker-list .stickers-wrapper::-webkit-scrollbar-thumb:hover {
      background-color: #555;
      /* Color of the scrollbar thumb on hover */ }

#camera #camera-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; }
  #camera #camera-wrapper canvas {
    width: 100% !important;
    height: auto !important;
    max-width: 100% !important;
    max-height: 100% !important;
    touch-action: none;
    box-shadow: 0px 31px 30.8px -14px rgba(171, 171, 171, 0.25); }

#camera .camera-bottom-button {
  position: fixed;
  left: 0;
  bottom: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  width: 100%; }
  #camera .camera-bottom-button .buttons {
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%; }
    #camera .camera-bottom-button .buttons.buttons-after-pic {
      margin-bottom: 10px; }
    #camera .camera-bottom-button .buttons.active {
      display: flex; }
    #camera .camera-bottom-button .buttons .buttons-group {
      display: flex;
      align-items: center; }
      #camera .camera-bottom-button .buttons .buttons-group.buttons-group-right {
        justify-content: space-between; }
      #camera .camera-bottom-button .buttons .buttons-group.buttons-group-left {
        flex-direction: row-reverse; }

#camera.preview-mode {
  width: 100%;
  height: 100%;
  background-image: url(/static/media/previewBackground.5fd542ac.png);
  background-size: cover; }
  #camera.preview-mode .preview-actions-wrapper {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
    #camera.preview-mode .preview-actions-wrapper .preview-title {
      color: black;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      line-height: 0.95;
      margin-bottom: 8px; }
    #camera.preview-mode .preview-actions-wrapper .preview-subtitle {
      color: black;
      font-weight: normal;
      font-size: 16px;
      text-align: center;
      margin-bottom: 20px;
      margin-top: 0; }
    #camera.preview-mode .preview-actions-wrapper .submit-button {
      color: black;
      margin-left: 20px;
      margin-right: 20px;
      padding: 14px 25px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      background-color: #d7c9ff;
      font-weight: bold;
      margin-bottom: 0;
      font-size: 17px;
      height: 48px;
      border-radius: 35px;
      border: 2px solid black;
      margin-bottom: 14px; }
    #camera.preview-mode .preview-actions-wrapper .return-button {
      color: black;
      margin-left: 20px;
      margin-right: 20px;
      padding: 0 38px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      background-color: white;
      font-weight: bold;
      margin-bottom: 0;
      font-size: 15px;
      height: 30px;
      border-radius: 35px;
      border: 1px solid black; }
  #camera.preview-mode .back-button {
    text-align: center;
    text-decoration: none;
    background-color: transparent;
    margin-bottom: 0;
    border: none;
    position: absolute;
    left: 24px;
    top: 8px;
    height: 48px; }
    #camera.preview-mode .back-button .back-arrow {
      width: 25px; }
  #camera.preview-mode #camera-wrapper {
    position: absolute;
    top: 10%;
    right: 15%;
    left: 15%;
    right: 15%;
    bottom: 0;
    width: 70%;
    height: 80%;
    z-index: 1; }

#event-closed .finished-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 5rem; }
  #event-closed .finished-wrapper .finished-image {
    width: 90px;
    margin-bottom: 20px; }
  #event-closed .finished-wrapper .finished-title {
    color: black;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    line-height: 0.95;
    margin-bottom: 0; }
  #event-closed .finished-wrapper .finished-subtitle {
    color: black;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    margin-bottom: 0; }

#event-finished .finished-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 5rem; }
  #event-finished .finished-wrapper .finished-image {
    width: 90px;
    margin-bottom: 20px; }
  #event-finished .finished-wrapper .finished-title {
    color: black;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    line-height: 0.95;
    margin-bottom: 0; }
  #event-finished .finished-wrapper .finished-subtitle {
    color: black;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    margin-bottom: 0; }

#event-waiting .waiting-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 5rem; }
  #event-waiting .waiting-wrapper .waiting-image {
    width: 90px;
    margin-bottom: 20px; }
  #event-waiting .waiting-wrapper .waiting-title {
    color: black;
    font-size: 24px;
    line-height: 0.95;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0; }
  #event-waiting .waiting-wrapper .waiting-subtitle-line-1,
  #event-waiting .waiting-wrapper .waiting-subtitle-line-2 {
    color: black;
    font-weight: normal;
    font-size: 18px;
    text-align: center;
    margin-bottom: 0; }

#event-limit-exceeded .wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 5rem; }
  #event-limit-exceeded .wrapper .image {
    width: 90px;
    margin-bottom: 20px; }
  #event-limit-exceeded .wrapper .title {
    color: black;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    line-height: 0.95;
    margin-bottom: 0; }
  #event-limit-exceeded .wrapper .subtitle-line-1,
  #event-limit-exceeded .wrapper .subtitle-line-2 {
    color: black;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    margin-bottom: 0; }

@font-face {
  font-family: "Blender";
  src: url("/fonts/Blender_Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: "Blender";
  src: url("/fonts/Blender_Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Blender";
  src: url("/fonts/Blender_Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal; }

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  box-sizing: border-box; }

html,
body,
#root,
#main {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-size: 1vw;
  font-family: "Blender", serif; }

html {
  direction: rtl; }

.hide {
  display: none; }

button {
  font-family: Blender, serif; }

