@import "../../vars";

#error-popup {
  .error-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10rem 0;

    .error-icon {
      width: 27rem;
      margin-bottom: 30px;
    }

    .error-title,
    .error-subtitle {
      color: black;
      font-size: 18px;
      line-height: 0.95;
      font-weight: bold;
      text-align: center;
      margin: 4px;
    }
  }
}
