@import "../../../../vars";

#camera-preview-btn {
  color: black;
  margin-left: 20px;
  margin-right: 20px;
  padding: 14px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  background-color: #d7c9ff;
  font-weight: bold;
  margin-bottom: 0;
  font-size: 17px;
  height: 48px;
  border-radius: 35px;
  border: 2px solid black;
}
