@import "../../../../vars";

#camera-sticker-list {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 4;
  height: 40%;
  background: #f1f1f1;
  width: 100%;
  border-radius: 26px 26px 0 0;
  border: 3px solid black;
  border-bottom: none;
  direction: ltr;

  .close-btn {
    background: none;
    border: none;
    padding: 0;
    position: absolute;
    right: 10px;
    top: 16px;
    margin: 0;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 20px;
    }
  }

  .stickers-wrapper {
    overflow-y: auto;
    height: 85%;
    position: absolute;
    margin-right: 10px;
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 5% 10% 10%;

    /* Custom scrollbar styles */
    &::-webkit-scrollbar {
      width: 12px; /* Width of the scrollbar */
    }

    &::-webkit-scrollbar-track {
      background: black; /* Background of the scrollbar track */
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d5ff6f; /* Color of the scrollbar thumb */
      border-radius: 10px;
      border: 3px solid black; /* Padding around the thumb */
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #555; /* Color of the scrollbar thumb on hover */
    }
  }
}
