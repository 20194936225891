@import "../../vars";

#camera {
  #camera-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    canvas {
      width: 100% !important;
      height: auto !important;
      max-width: 100% !important;
      max-height: 100% !important;
      touch-action: none;
      -webkit-box-shadow: 0px 31px 30.8px -14px rgba(171, 171, 171, 0.25);
      -moz-box-shadow: 0px 31px 30.8px -14px rgba(171, 171, 171, 0.25);
      box-shadow: 0px 31px 30.8px -14px rgba(171, 171, 171, 0.25);
    }
  }

  .camera-bottom-button {
    position: fixed;
    left: 0;
    bottom: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    width: 100%;

    .buttons {
      display: none;
      justify-content: center;
      align-items: center;
      width: 100%;

      &.buttons-after-pic {
        margin-bottom: 10px;
      }

      &.active {
        display: flex;
      }

      .buttons-group {
        display: flex;
        align-items: center;

        &.buttons-group-right {
          justify-content: space-between;
        }

        &.buttons-group-left {
          flex-direction: row-reverse;
        }
      }
    }
  }

  &.preview-mode {
    width: 100%;
    height: 100%;
    background-image: url("./images/previewBackground.png");
    background-size: cover;

    .preview-actions-wrapper {
      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .preview-title {
        color: black;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        line-height: 0.95;
        margin-bottom: 8px;
      }

      .preview-subtitle {
        color: black;
        font-weight: normal;
        font-size: 16px;
        text-align: center;
        margin-bottom: 20px;
        margin-top: 0;
      }

      .submit-button {
        color: black;
        margin-left: 20px;
        margin-right: 20px;
        padding: 14px 25px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        background-color: #d7c9ff;
        font-weight: bold;
        margin-bottom: 0;
        font-size: 17px;
        height: 48px;
        border-radius: 35px;
        border: 2px solid black;
        margin-bottom: 14px;
      }

      .return-button {
        color: black;
        margin-left: 20px;
        margin-right: 20px;
        padding: 0 38px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        background-color: white;
        font-weight: bold;
        margin-bottom: 0;
        font-size: 15px;
        height: 30px;
        border-radius: 35px;
        border: 1px solid black;
      }
    }
    .back-button {
      text-align: center;
      text-decoration: none;
      background-color: transparent;
      margin-bottom: 0;
      border: none;
      position: absolute;
      left: 24px;
      top: 8px;
      height: 48px;

      .back-arrow {
        width: 25px;
      }
    }

    #camera-wrapper {
      position: absolute;
      top: 10%;
      right: 15%;
      left: 15%;
      right: 15%;
      bottom: 0;
      width: 70%;
      height: 80%;
      z-index: 1;
    }
  }
}
