@import "../../../_vars";

.popup-screen {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  background: rgba(white, 0.5);

  .popup-area {
    width: 80%;
    border: 3px solid black;
    background: white;
    padding: 3.2rem;
    border-radius: 26px;
  }

  .popup-close {
    margin-top: 2px;
    margin-right: 2px;

    img {
      width: 18px;
    }

    &.show {
      display: block;
    }

    &.hide {
      display: none;
    }
  }
}
