@import url(//fonts.googleapis.com/earlyaccess/opensanshebrew.css);
@import "../../vars";

@font-face {
  font-family: "Blender";
  src: url("/fonts/Blender_Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Blender";
  src: url("/fonts/Blender_Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Blender";
  src: url("/fonts/Blender_Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;
  box-sizing: border-box;
}

html,
body,
#root,
#main {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-size: 1vw;
  font-family: "Blender", serif;
}

html {
  direction: rtl;
}

.hide {
  display: none;
}

button {
  font-family: Blender, serif;
}
