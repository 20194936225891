@import "../../../_vars";
.memo-ad-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .want-memo-at-your-event {
    color: black;
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    line-height: 0.95;
    margin-bottom: 12px;
    margin-top: 18px;
  }

  .click-for-more-details {
    color: black;
    padding: 14px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    background-color: white;
    font-weight: bold;
    margin-bottom: 0;
    font-size: 17px;
    height: 48px;
    border-radius: 35px;
    border: 2px solid black;
  }
}
