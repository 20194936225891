@import "../../../_vars";

.main-screen {
  width: 100%;
  height: 100%;
  background-image: url("./images/gradientBackground.png");
  background-size: cover;
  position: relative;
  padding: 4%;
}

.main-screen-body {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
}

.main-screen-footer {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  margin-bottom: 32px;
}

.main-screen-wrapper {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
      to right,
      black 5%,
      transparent 5%,
      transparent 45%,
      black 45%,
      black 75%,
      transparent 75%,
      transparent 95%,
      black 95%
    ),
    linear-gradient(
      to bottom,
      black 2%,
      transparent 2%,
      transparent 10%,
      black 10%,
      black 25%,
      transparent 25%,
      transparent 45%,
      black 45%,
      black 50%,
      transparent 50%,
      transparent 60%,
      black 60%,
      black 75%,
      transparent 75%,
      transparent 98%,
      black 98%
    ),
    linear-gradient(
      to bottom,
      black 2%,
      transparent 2%,
      transparent 20%,
      black 20%,
      black 35%,
      transparent 35%,
      transparent 45%,
      black 45%,
      black 50%,
      transparent 50%,
      transparent 70%,
      black 70%,
      black 85%,
      transparent 85%,
      transparent 98%,
      black 98%
    ),
    linear-gradient(
      to left,
      black 5%,
      transparent 5%,
      transparent 45%,
      black 45%,
      black 75%,
      transparent 75%,
      transparent 95%,
      black 95%
    );
  background-size: 100% 2px, 2px 100%, 2px 100%;
  background-position: top left, top left, top right, bottom right;
  background-repeat: no-repeat;
  padding: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
