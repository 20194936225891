@import "../../../vars";

#event-finished {
  .finished-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 5rem;

    .finished-image {
      width: 90px;
      margin-bottom: 20px;
    }

    .finished-title {
      color: black;
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      line-height: 0.95;
      margin-bottom: 0;
    }

    .finished-subtitle {
      color: black;
      font-weight: normal;
      font-size: 16px;
      text-align: center;
      margin-bottom: 0;
    }
  }
}
