@import '../../../../vars';

.sticker-item {
    width: 27%;
    height: 50%;
    margin-bottom: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &.loading {
        .loading-image {
            display: block;
        }

        .sticker-image {
            display: none;
        }
    }
    
    .loading-image {
        width: 30%;
        display: none;
    }

    .sticker-image {
        width: 100%;
        max-height: 100%;
    }
}