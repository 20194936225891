@import "../../vars";

.open-camera-wrapper {
  text-align: center;
}

.event-title {
  font-weight: normal;
  font-size: 20px;
  margin-bottom: 10px;
}

.event-subtitle {
  font-weight: bold;
  font-size: 40px;
  margin-top: 0;
  margin-bottom: 100px;
}

.camera-img {
  transition: all 250ms linear;
  margin-bottom: 150px;
}

.camera-img:focus {
  transform: scale(1.2);
  opacity: 0;
}
