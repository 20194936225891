@import "../../../vars";

#event-waiting {
  .waiting-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 5rem;

    .waiting-image {
      width: 90px;
      margin-bottom: 20px;
    }

    .waiting-title {
      color: black;
      font-size: 24px;
      line-height: 0.95;
      font-weight: bold;
      text-align: center;
      margin-bottom: 0;
    }

    .waiting-subtitle-line-1,
    .waiting-subtitle-line-2 {
      color: black;
      font-weight: normal;
      font-size: 18px;
      text-align: center;
      margin-bottom: 0;
    }
  }
}
